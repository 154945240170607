.go_to_cart {
  display: flex;
  gap: 8px;

  > button {
    width: 235px;
    background-color: #13ED29;
    color: #141212;
    font-size: 16px;
    font-style: normal;
    line-height: 32px;
    border-radius: 32px;
    font-family: 'Onest Medium';

    @media (max-width: 425px) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .counter {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      display: flex;
      width: 72px;
      height: 56px;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 32px;
      background: #2C2A2A;

      svg {
        color: rgba(255, 255, 255, 1);
        width: 24px;
        height: 24px;
      }
    }

    span {
      color: #FFF;
      font-size: 20px;
      font-style: normal;
      line-height: 32px;
      font-family: 'Onest Regular';
      font-weight: 600;
    }
  }
}

.add_to_cart {
  background: #13ED29;
  color: #141212;
  border-radius: 32px;
  height: 56px;
  padding: 4px 16px;
  font-size: 16px;
  font-style: normal;
  line-height: 32px;
  font-family: 'Onest Medium';
}