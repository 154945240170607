.card {
  width: 546px;

  @media (max-width: 790px) {
    width: 356px;
  }

  @media (max-width: 320px) {
    width: 300px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .card_inner {
    width: 100%;
    height: 656px;
    position: relative;
    cursor: pointer;
    @media (max-width: 790px) {
      height: 450px;
    }

    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.00) 100%);
      width: 100%;
      height: 141px;
      border-radius: 20px;
    }
    img {
      border-radius: 20px;
      width: 100%;
      height: 656px;
      object-fit: cover;
      object-position: center;

      @media (max-width: 790px) {
        height: 434px;
      }


      @media (max-width: 320px) {
        width: 300px;
      }
    
    }

    p {
      position: absolute;
      top: 24px;
      left: 24px;
      color: #FFF;
      font-size: 32px;
      font-style: normal;
      line-height: 32px;          
      font-family: 'Onest Medium';
      z-index: 2;
    }

  }

  .card_down {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    // @media (max-width: 790px) {
    //   flex-direction: column;
    // }

    .card_btn {
      display: flex;
      gap: 8px;

      button {
        background: none;
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        line-height: 32px;
        font-family: 'Onest Medium';
        @media (max-width: 790px){
          font-size: 20px;
          line-height: 25px;
        }
        @media (max-width: 425px){
          font-size: 16px;
          line-height: 25px;
        }
        // @media (max-width: 320px) {
        //   font-size: 20px;
        // }

        &.added {
          color: #13ED29;
        }
      }
    }

    .price {
      // @media (max-width: 790px) {
      //   text-align: right;
      //   width: 100%;
      //   margin-top: 50px;
      // }

      p {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        line-height: 32px;
        font-family: 'Onest Medium';
        margin: 0;
        @media (max-width: 425px){
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }

}