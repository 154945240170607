.policy{
  width: 90%;
  max-width: 343px;
  margin-bottom: 50px;
  p{
    text-align: center;
    color: rgba($color: white, $alpha: 60%);
    font-size: 12px;
    font-weight: 500;
    a{
      text-decoration: none;
      color: #13ED29;
    }
  }
}