.card1 {
  &:nth-child(1) {
    background-color: #737171;
    transform: translate3d(0px, 0px, 0px) rotateX(-25deg) scale(0.8);
  }
  
}

.card2 {
  &:nth-child(1) {
    background-color: #737171;
    transform: translate3d(0px, 0px, 0px) rotateX(-25deg) scale(0.8);
  }

  &:nth-child(2) {
    background-color: #949393;
    transform: translate3d(0px, 50px, 0px) rotateX(-25deg) scale(0.85);

    @media (max-width: 1024px) {
      top: 40px;
    }
  }
  
}

.card3 {
  &:nth-child(1) {
    background-color: rgb(80, 80, 80);
    transform: translate3d(0px, 0px, 0px) rotateX(-25deg) scale(0.8);
    
  }

  &:nth-child(2) {
    background-color: #737171;
    transform: translate3d(0px, 50px, 0px) rotateX(-25deg) scale(0.85);
  }

  &:nth-child(3) {
    background-color: #949393;
    transform: translate3d(0px, 100px, 0px) rotateX(-25deg) scale(0.9);
  }
  
}


.card {
  width: 100%;
  height: 545px;
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  position: sticky;
  top: 120px;
  transition: .5s;
  @media (max-width: 1024px) {
    top: 0;
  }


  @media (max-width: 768px) {
    height: initial;
    align-items: start;
  }
  @media (max-width: 500px) {
    position: static;
    width: 480px;
  }
  @media (max-width: 430px) {
    width: 377px;
  }
  @media (max-width: 375px) {
    width: 343px;
  }
  @media (max-width: 320px) {
    width: 300px;
  }

  .title {
    padding-top: 48px;
    padding-left: 48px;

    @media (max-width: 768px) {
      padding-top: 24px;
      padding-left: 24px;
    }

    h1 {
      color: #000;
      font-size: 48px;
      font-style: normal;
      line-height: 72px;
      font-family: 'Onest Regular';
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;
      padding: 0;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    div {
      color: #65AF77;
      font-size: 285px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      margin: 0;
      display: none;

      @media (max-width: 768px) {
        font-size: 80px;
        display: block;
      }
    }
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    height: 280px;
    margin-top: auto;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
      height: initial;
      margin-top: 0;
      padding: 0 24px;
    }

    @media (max-width: 560px) {
      height: auto;
    }

    div {
      color: #65AF77;
      font-size: 285px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 80px;
        display: none;
      }
    }

    p {
      width: 500px;
      color: #000;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      font-family: 'Onest Regular';

      @media (max-width: 560px) {
        width: 100%;
        color: rgba(0, 0, 0, 0.60);
      }
    }
  }
}

// @keyframes anim {
//   0% {
//     transform: translateY(0) translateZ(0) rotateX(0deg);
//   }
//   100% {
//     transform: translateY(-150px) translateZ(-130px) rotateX(-90deg) scale(.8);
//   }
// }