.promocode{
  width: 90%;
  max-height: 298px;
  border-radius: 24px;
  background: rgba($color: #fff, $alpha: 10%);
  margin-bottom: 12px;
  padding: 18px 24px;
  position: relative;
  margin-top: 16px;
  overflow: hidden;
  transition: .2s;  
  cursor: pointer;
  
  @media (max-width: 1242px) {
    width: 95%;
    margin-bottom: 16px;
  } 
  @media (max-width: 1130px) {
    width: 90%;
  } 
  @media (max-width: 762px) {
    width: 84%;
  } 
  @media (max-width: 416px) {
    width: 76%;
  } 
  @media (max-width: 500px) {
    width: 329px;
  }
  @media (max-width: 375px) {
    width: 295px;
  }
  @media (max-width: 320px) {
    width: 224px;
  }
  li{
    width: 32px;
    height: 32px;
    list-style: none;
    color: white;
    background: #414040;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .left{
      h3{
        color: white;
        font-weight: 500;
        font-size: 20px;
        margin: 0;
        margin-left: 8px;
        font-family: 'Onest Regular';
        font-weight: 500;

        @media (max-width: 320px) {
          font-size: 16px;
        }
      }
    }
    .right{
      span{
        color: white;
        font-size: 24px;
      }
    }
  }
  .disactive{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    z-index: -100;
    transition: .2s;
    opacity: 0;
    fill-opacity: 0;
    input{
      width: 88%;
      height: 56px;
      padding: 0 24px;
      background: rgba($color: white, $alpha: 10%);
      color: white;
      font-size: 16px;
      font-weight: bold;
      border-radius: 50px;
      border: 0;
      outline: 0;
      display: none;
    
    }
    button{
      width: 100%;
      height: 56px;
      border-radius: 50px;
      margin-top: 16px;
      background-color: white;
      color: #141212;
      font-size: 16px;
      font-weight: bold;
      display: none;
      border: 0;
      cursor: pointer;
    }
  }
  .active{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 24px 0;
    position: none;
    top: 0%;
    left: 0;
    transition: .2s;
    opacity: 1;
    input{
      width: 88%;
      height: 56px;
      padding: 0 24px;
      background: rgba($color: white, $alpha: 10%);
      color: white;
      font-size: 16px;
      font-family: 'Onest Bold';
      letter-spacing: 1px;
      border-radius: 50px;
      border: 0;
      outline: 0;
      margin-top: 20px;
      &.active{
        border: 1px solid #FFFFFF
      }
      @media (max-width: 1242px) {
        width: 95%;
      } 
      @media (max-width: 760px) {
        width: 92%;
      }
      @media (max-width: 568px) {
        width: 86%;
      }
    }
    p{
      width: 100%;
      color: #FE2C2C;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      margin-top: 8px;
      display: flex;
      justify-content: flex-start;
    }
    button{
      width: 100%;
      height: 56px;
      border-radius: 50px;
      margin-top: 24px;
      background-color: white;
      color: #141212;
      font-size: 16px;
      font-family: 'Onest Medium';
      font-weight: 600;
      border: 0;
      cursor: pointer;
      &.success{
        background: #13ED29;
      }
    }
    
  }
}