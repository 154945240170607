.products {
  width: 83%;
  max-width: 1440px;
  margin: 130px auto 0;

  .title {
    text-align: center;
    margin-bottom: 72px;

    h1 {
      color: #FFF;
      font-size: 32px;
      font-style: normal;
      font-family: 'Onest Regular';
      font-weight: 700;
      line-height: 32px;
      margin: 0;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 56px;

    @media (max-width: 790px) {
      gap: 140px;
    }

    @media (max-width: 500px) {
      gap: 40px;
    }

    @media (max-width: 320px) {
      gap: 50px;
    }

    >div:nth-child(even) {
      margin-top: 50px;

      @media (max-width: 500px) {
        margin-top: 0;
      }
    }
  }
}