.size_swiper {
  padding: 2px !important;
  .size {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    P {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      padding: 16px 12px;
      margin: 0;
    }
  }
  .active{
    outline: 2px solid #13ED29;
    border-radius: 12px;
  }
}