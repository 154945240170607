.more {
  // width: 95%;
  // max-width: 1440px;
  margin: 90px auto 0;

  margin-bottom: 50px;

  @media (max-width: 1366px) {
    width: 95%;
  }

  @media (max-width:768px) {
    margin-top: 120px;
    width: 100vw;
  }

  .container {
    .pages {
      @media (max-width:768px) {
        display: flex;
        justify-content: center;
      }

      @media (max-width:500px) {
        display: none;
      }

      h3 {
        color: white;
        display: flex;
        align-items: center;
        font-family: 'Onest Regular';
        font-weight: 500;
        font-size: 14px;
        margin: 0;

        span {
          display: flex;
          align-items: center;
          color: #FFFFFF99;

          .home {
            font-size: 25px;
          }

          p {
            cursor: pointer;
          }

          span {
            margin: 0 12px;
          }
        }
      }
    }

    .cont {
      display: flex;
      justify-content: center;
      gap: 24px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .slider {
        width: 204px;
        height: 850px;
        overflow: hidden;
        position: relative;

        @media (max-width: 1024px) {
          height: 700px;
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      .active_img {
        height: 850px;
        max-width: 660px;

        @media (max-width: 1024px) {
          height: 700px;
          max-width: 400px;
        }

        @media (max-width: 768px) {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 32px;
          object-fit: cover;
        }
      }

      .mobile_slider {
        display: none;

        @media (max-width: 768px) {
          display: block;
          width: 100%;
        }
      }


      .info {
        width: 432px;

        @media (max-width: 1024px) {
          width: 360px;
        }

        @media (max-width: 768px) {
          margin: 0 auto;
        }

        @media (max-width: 500px) {
          width: 377px;
        }

        @media (max-width: 375px) {
          width: 343px;
        }

        @media (max-width: 320px) {
          width: 300px;
        }

        >div {
          width: 100%;
        }

        .info_title {
          color: #fff;

          .new_text {
            color: #13ED29;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 16px;

            p {
              margin: 0;
              font-size: 16px;
              font-style: normal;
              line-height: 24px;
              text-transform: uppercase;
              font-family: 'Onest Medium';

            }
          }

          .title {
            margin-bottom: 16px;

            h1 {
              color: #FFF;
              font-size: 32px;
              font-style: normal;
              font-family: 'Onest Regular';
              font-weight: 600;
              line-height: 40px;
              margin: 0;
              padding: 0;

              @media (max-width: 1024px) {
                font-size: 25px;
                line-height: 30px;
              }
            }
          }

          .desc {
            p {
              color: rgba(255, 255, 255, 0.60);
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              margin: 0;
            }
          }
        }

        .price {
          margin-top: 24px;

          h1 {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-family: 'Onest Regular';
            line-height: 48px;
            margin: 0;

            @media (max-width: 1024px) {
              line-height: 30px;
            }
          }
        }

        .size {
          margin-top: 32px;
          position: relative;

          .choose_size {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 1024px) {
              flex-direction: column;
              align-items: start;
            }

            @media (max-width: 768px) {
              flex-direction: initial;
              align-items: center;
            }

            .choose {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              line-height: 24px;
              font-family: 'Onest Medium';

              @media (max-width: 320px) {
                font-size: 15px;
                line-height: 20px;
              }

            }

            .setka {
              display: flex;
              align-items: center;

              span {
                color: #13ED29;
                font-size: 16px;
                font-style: normal;
                font-family: 'Onest Regular';
                line-height: 24px;

                @media (max-width: 320px) {
                  font-size: 15px;
                  line-height: 20px;
                }
              }
            }
          }


          .dropdown {
            border-radius: 12px;
            cursor: pointer;
            margin-top: 16px;
            overflow: hidden;
            // transition: .1s;

            @media (max-width: 768px) {
              display: none;
            }

            @media (max-width: 500px) {
              width: 343px;
            }

            @media (max-width: 375px) {
              width: 310px;
            }

            @media (max-width: 351px) {
              width: 86%;
            }

            @media (max-width: 320px) {
              width: 264px;
            }

            .up {
              display: flex;
              align-items: center;
              justify-content: space-between;
              z-index: 10;
              background: #2C2A2A;
              border-radius: 12px;
              height: 56px;
              padding: 0 16px 0 20px;
              li {
                list-style: none;
                font-family: 'Onest Medium';
                font-weight: 500;
                color: white;
                margin: 0;
                font-size: 20px;
              }

              span {
                font-size: 26px;
                display: flex;
                align-items: center;
                margin: 0;
                color: white;
                transition: .5s;
              }

              .active_arrow {
                transform: rotate(180deg);
              }

            }

            .container {
              // position: relative;
              overflow: hidden;
              // transition: .5s;
              .down_none {
                margin-top: 12px;
                border-top: 1px solid rgba(255, 255, 255, 0.10);
                // transform: translateY(-100%);
                position: absolute;
                // transition: .5s;
                background: #2C2A2A;
                padding: 0 16px 12px 12px;
                z-index: -2;
                opacity: 0;
                li {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 12px 8px;
                  color: #FFF;
                  font-family: 'Onest Medium';
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                  /* 120% */
                  background: #2C2A2A;
                  border-radius: 12px;

                  &.active {
                    background: rgba(255, 255, 255, 0.10);
                    border-radius: 12px;
                  }

                }
              }

              .down_active {
                margin-top: 6px;
                // border-top: 1px solid rgba(255, 255, 255, 0.10);
                // transform: translateY(0%);
                position: none;
                // transition: .5s;
                background: #2C2A2A;
                border-radius: 12px;
                padding: 12px;
                position: absolute;
                width: 408px;
                li {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 12px 8px;
                  color: #FFF;
                  font-family: 'Onest Medium';
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                  /* 120% */
                  background: #2C2A2A;
                  border-radius: 12px;
                  transition: .2s;
                  &.active {
                    background: rgba(255, 255, 255, 0.10);
                    border-radius: 12px;
                  }
                  &:hover{
                    background: rgba(255, 255, 255, 0.10);
                  }
                }
              }
            }

          }

          .size_slider {
            display: none;

            @media (max-width: 768px) {
              display: block;
              margin-top: 16px;
            }
          }

          .model {
            border-radius: 12px;
            border: 1px solid rgba(255, 255, 255, 0.60);
            margin-top: 8px;
            padding: 12px 16px 12px 20px;

            .model_text {
              display: flex;
              align-items: center;
              gap: 8px;

              div {
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
                font-family: 'Onest Medium';
                font-weight: 500;

                span {
                  color: rgba(255, 255, 255, 0.60);
                }
              }
            }
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: 32px;

          button {
            border-radius: 32px;
            height: 56px;
            padding: 4px 16px;

            font-size: 16px;
            font-style: normal;
            line-height: 32px;
            font-family: 'Onest Medium';

          }

          .buy {
            background: #2C2A2A;
            color: #FFF;
          }
        }

        .consist {
          margin-top: 48px;

          @media (max-width: 1024px) {
            margin-top: 30px;
          }

          p {
            margin: 0;
            color: rgba(255, 255, 255, 0.60);
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            margin-bottom: 8px;
            font-family: 'Onest Regular';
            font-weight: 500;

            @media (max-width: 1024px) {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        .delivery_info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          cursor: pointer;

          div {
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            line-height: 24px;
            font-family: 'Onest Medium';
            font-weight: 500;

            @media (max-width: 1024px) {
              font-size: 16px;
              line-height: 20px;
            }
          }

          span {
            width: 32px;
            height: 32px;

            svg {
              width: 100%;
              height: 100%;
              color: #FFF;
            }
          }
        }

        .add_to_cart_mob {
          margin-top: 37px;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}