.deliveryInfo{
  padding: 24px;
  background: #2C2A2A;
  border-radius: 24px;
  width: 660px;
  @media (max-width: 490px) {
    width: 80%;
  }
  @media (max-width: 1242px) {
    width: 95%;
    max-width: none;
  } 
  @media (max-width: 1130px) {
    width: 90%;
  } 

  @media (max-width: 762px) {
    width: 84%;
  } 
  @media (max-width: 416px) {
    width: 76%;
  } 
  @media (max-width: 500px) {
    width: 345px;
    padding: 16px;
  }
  @media (max-width: 375px) {
    width: 311px;
  }
  @media (max-width: 320px) {
    width: 270px;
  } 
  h3{
    color: white;
    font-size: 20px;
    line-height: 28px;
    font-family: 'Onest Regular';
    font-weight: 500;
    margin: 0;
    @media (max-width: 320px) {
      font-size: 16px;
    } 
  }
  
  form{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px 38px;
    margin-top: 16px;
    @media (max-width: 654px) {
      flex-direction: column;
    }
    div{
      width: 45%;
      position: relative;
      @media (max-width: 654px) {
        width: 97.3%;
      }
      input{
        width: 302px;
        height: 32px;
        outline: none;
        padding: 0 12px;
        padding-top: 14px;
        background: rgba($color: white, $alpha: 5%);
        border-radius: 12px;
        font-size: 14px;
        font-weight: 500;
        color: white;
        transition: .2s;
        border: 1px solid rgba($color: white, $alpha: 0%);
        visibility: visible;
        @media (max-width: 1240px) {
          width: 100%;
        }
        @media (max-width: 632px) {
          width: 95%;
        }
        @media (max-width: 375px) {
          width: 285px;
        }
        @media (max-width: 320px) {
          width: 245px;
        } 
        &:focus{
          border: 1px solid white;
          transition: .3s;
        }
        &:hover{
          border: 1px solid rgba($color: white, $alpha: 20%);
        }
        &.active{
          border: 1px solid white;
        }
      }
      span{
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        color: rgba($color: white, $alpha: 60%);
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        transition: .3s;
        pointer-events: none;
        &.active{
          transform: translateY(-32%);
          position: absolute;
          left: 13px;
          top: 14px;
          font-size: 11px;
          color: rgba($color: white, $alpha: 60%);
          line-height: 18px;
          transition: .3s;
        }
      }
    }
  }
  .address{
    margin-top: 32px;
    height: auto;

    @media (max-width: 748px) {
      width: 100%;
      height: 100%;
    } 
    h3{
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      @media (max-width: 320px) {
        font-size: 16px;
      }
    }
    p{
      margin: 8px 0 16px 0;
      color: rgba($color: white, $alpha: 60%);
      font-size: 16px;
      font-family: 'Onest Regular';
      @media (max-width: 500px) {
        font-size: 14px;
      }
      &.parag{
        @media (max-width: 500px) {
          display: none;
        }
      }
    }
    .inputForm{
      width: 100%;
      height: auto;

      position: relative;
      .up{
        width: 100%;
        height: auto;

        display: flex;
        flex-wrap: wrap;
        gap: 8px 38px;
        margin-top: 16px;
        @media (max-width: 654px) {
          flex-direction: column;
        }
        div{
          width: 45%;
          height: auto;
          position: relative;
          @media (max-width: 654px) {
            width: 95%;
          }
          input{
            width: 302px;
            height: 32px;
            outline: none;
            padding: 0 12px;
            padding-top: 14px;
            background: rgba($color: white, $alpha: 5%);
            border-radius: 12px;
            font-size: 14px;
            font-weight: 500;
            color: white;
            transition: .2s;
            border: 1px solid rgba($color: white, $alpha: 0%);
            visibility: visible;
            @media (max-width: 1240px) {
              width: 100%;
            }
            @media (max-width: 632px) {
              width: 95%;
            }
            @media (max-width: 500px) {
              width: 319px;
            }
            @media (max-width: 375px) {
              width: 285px;
            }
            @media (max-width: 320px) {
              width: 245px;
            } 
            &:focus{
              border: 1px solid white;
              transition: .3s;
            }
            &:hover{
              border: 1px solid rgba($color: white, $alpha: 20%);
            }
            &.active{
              border: 1px solid white;
            }
          }
          span{
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            color: rgba($color: white, $alpha: 60%);
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            transition: .3s;
            pointer-events: none;
            &.active{
              transform: translateY(-32%);
              position: absolute;
              left: 13px;
              top: 14px;
              font-size: 11px;
              color: rgba($color: white, $alpha: 60%);
              line-height: 18px;
              transition: .3s;
            }
          }
          .cityRes{
            width: 328px;
            max-height: 290px;
            overflow: auto !important;
            margin-top: 8px;
            border-radius: 12px;
            @media (max-width: 1240px) {
              width: 100%;
            }
            background-color: #373535;
            position: absolute;
            left: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            gap: 12px;
            &::-webkit-scrollbar{
              width: 4px;
              background: rgba(255, 255, 255, 0.15);
              border-radius: 0 50px 50px 0;
            }
            &::-webkit-scrollbar-thumb{
              width: 4px;
              height: auto;
              background: white;
              border-radius: 50px;
            }
            div{
              border-bottom: 1px solid #FFFFFF26;
              width: 90%;
              height: 56px;
              display: flex;
              align-items: center;
              position: relative;
              p{
                padding: 12px 0;
                margin: 0;
                cursor: pointer;
              }
              
            }
          }
        }
        
      }
      .down{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 8px 38px;
        margin-top: 8px;
        @media (max-width: 654px) {
          flex-direction: column;
        }
        @media (max-width: 1237px) {
          gap: 8px 46px;
        }
        @media (max-width: 874px) {
          gap: 8px 40px;
        }


        div{
          width: 28%;
          position: relative;
          @media (max-width: 654px) {
            width: 95%;
          }
          input{
            width: 192px;
            height: 32px;
            outline: none;
            padding: 0 12px;
            padding-top: 14px;
            background: rgba($color: white, $alpha: 5%);
            border-radius: 12px;
            font-size: 14px;
            font-weight: 500;
            color: white;
            transition: .2s;
            border: 1px solid rgba($color: white, $alpha: 0%);
            visibility: visible;
            @media (max-width: 1240px) {
              width: 100%;
            }
            @media (max-width: 1237px) {
              width: 104%;
            }
            @media (max-width: 900px) {
              width: 102%;
            }
            @media (max-width: 700px) {
              width: 100%;
            }
            @media (max-width: 632px) {
              width: 95%;
            }
            @media (max-width: 500px) {
              width: 319px;
            }
            @media (max-width: 375px) {
              width: 285px;
            }
            @media (max-width: 320px) {
              width: 245px;
            } 
            &:focus{
              border: 1px solid white;
              transition: .3s;
            }
            &:hover{
              border: 1px solid rgba($color: white, $alpha: 20%);
            }
            &.active{
              border: 1px solid white;
            }
          }
          
          span{
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            color: rgba($color: white, $alpha: 60%);
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            transition: .3s;
            pointer-events: none;
            &.active{
              transform: translateY(-32%);
              position: absolute;
              left: 13px;
              top: 14px;
              font-size: 11px;
              color: rgba($color: white, $alpha: 60%);
              line-height: 18px;
              transition: .3s;
            }
          }
        }
        .comment_div{
          width: 100%;
          position: relative;
          @media (max-width: 654px) {
            width: 95%;
          }
          margin-top: 24px;
          #comment{
            width: 97%;
            height: 32px;
            outline: none;
            padding: 0 12px;
            padding-top: 14px;
            background: rgba($color: white, $alpha: 5%);
            border-radius: 12px;
            font-size: 14px;
            font-weight: 500;
            color: white;
            transition: .2s;
            border: 1px solid rgba($color: white, $alpha: 0%);
            visibility: visible;
            @media (max-width: 1230px) {
              width: 94%;
            }
            @media (max-width: 904px) {
              width: 95%;
            }
            @media (max-width: 710px) {
              width: 97%;
            }
            @media (max-width: 636px) {
              width: 100%;
            }
            @media (max-width: 590px) {
              width: 95%;
            }
            &:focus{
              border: 1px solid white;
              transition: .3s;
            }
            &:hover{
              border: 1px solid rgba($color: white, $alpha: 20%);
            }
            &.active{
              border: 1px solid white;
            }
            &.active_comment{
              border: 1px solid white;
            }
          }
          span{
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            color: rgba($color: white, $alpha: 60%);
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            transition: .3s;
            pointer-events: none;
            &.active{
              transform: translateY(-32%);
              position: absolute;
              left: 13px;
              top: 14px;
              font-size: 11px;
              color: rgba($color: white, $alpha: 60%);
              line-height: 18px;
              transition: .3s;
            }
          }
        }
      }
    }
  }
}