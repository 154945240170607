.about_container {
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1366px) {
    margin-top: 100px;
  }

  @media (max-width: 430px) {
    margin-top: 70px;
  }

  .about {
    width: 95%;
    margin: 50px auto;
    min-height: 500px;
    background: url('../../img/background.png') center / cover;
    backdrop-filter: blur(76px);
    display: flex;
    align-items: center;
    color: #fff;
    gap: 60px;
    justify-content: center;
    position: relative;
    border-radius: 24px;

    @media (min-width: 1440px) {
      margin: 200px 0;
    }

    @media (max-width: 1360px) {
      gap: 40px;
    }

    @media (max-width: 500px) {
      width: 480px;
      margin-top: 120px;
      background-color: #2A2929;
      background-image: none;

    }

    @media (max-width: 430px) {
      width: 377px;

    }

    @media (max-width: 375px) {
      width: 343px;
    }

    @media (max-width: 320px) {
      width: 300px;
    }


    @media (max-width: 1050px) {
      flex-direction: column;
      gap: 0;
      padding-bottom: 75px;
    }

    .gray {
      color: rgba(255, 255, 255, 0.6);
    }

    .right {
      width: 50%;

      @media (max-width: 1050px) {
        width: 100%;
        text-align: center;
      }

      p {
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
        padding-left: 72px;
        font-family: 'Onest Medium';

        @media (max-width: 1230px) {
          padding-left: 36px;
        }

        @media (max-width: 1050px) {
          font-size: 20px;
          line-height: 30px;
        }

        @media (max-width: 500px) {
          text-align: left;
          width: 270px;
          margin-right: 16px;
          margin-left: auto;
        }

        .ml {
          margin-right: 100px;

          @media (max-width: 1360px) {
            margin-right: 50px;
          }

          @media (max-width: 1230px) {
            margin-right: 20px;
          }

          @media (max-width: 500px) {
            margin-left: 73px;
          }
        }
      }

      .text_down {
        position: absolute;
        bottom: 72px;
        left: 72px;
        color: #FFF;
        font-size: 12px;
        font-family: 'Onest Regular';
        font-weight: 500;
        margin: 0;
        line-height: 16px;
        text-transform: uppercase;
        .gray{
          margin-left: 25px;
          @media (max-width: 1050px) {
            margin-left: 100px;
          }
        }
        @media (max-width: 1050px) {
          bottom: 16px;
          left: 0;
          margin-top: 32px;
        }
        @media (max-width: 500px) {
          bottom: 16px;
          left: -54px;
          margin-top: 32px;
        }
      }
    }

    .left {
      width: 50%;
      padding: 72px 72px 72px 0;

      @media (max-width: 1230px) {
        padding: 15px 36px 36px 0;
      }

      @media (max-width: 1050px) {
        width: 100%;
        margin: 0 auto;
        padding: 0;
      }

      .p1 {
        margin-bottom: 88px;

        @media (max-width: 1050px) {
          margin-bottom: 30px;
        }
      }

      .p1 {
        @media (max-width: 1050px) {
          margin-bottom: 32px;
        }
      }

      p {
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        font-family: 'Onest Regular';
        font-weight: 500;

        @media (max-width: 1050px) {
          padding: 0 20px;
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
}