.container{
  width: 100%;
  display: flex;
  justify-content: center;
  
  .main{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width:320px) {
      width: 320px;
      overflow-x: hidden;
    }
    @media (min-width:1440px) {
      width: 100%;
    }
  }
}