.container{
    width: 100%;
    display: flex;
    align-items: center;    
    flex-direction: column;
    @media (max-width: 425px) {
        width: 380px;
    }
    @media (max-width: 375px) {
        width: 343px;
    }
    @media (max-width: 320px) {
        width: 320px;
    }
    .cart{
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
        margin-top: 12px;
        margin-bottom: 50px;
        @media (max-width: 1350px) {
            align-items: center;
            flex-direction: column;
            gap: 24px;
        }
        .cards{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 24px;
            @media (max-width: 1350px) {
                width: 100%;
            } 
        }
        .nothing{
            width: 660px;
            max-height: 298px;
            border-radius: 24px;
            margin-bottom: 12px;
            padding: 24px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 768px) {
                width: 94%;
                padding: 18px;
            }
            h2{
                color: white;
            }
        }
        .right{
            display: flex;
            align-items: center;
            flex-direction: column;
            max-width: 384px;
            margin-left: 24px;
            @media (max-width: 1350px) {
                width: 100%;
                max-width: none;
                margin: 0;
            }
            .info{
                width: 100%;
                max-width: 432px;

                @media (max-width: 1350px) {
                    width: 95%;
                    display: none;
                } 
                

                // @media (max-width: 768px) {
                // }
                // @media (max-width: 1268px) {
                //     width: 660px;
                //     align-items: center;
                // }
                // @media (max-width: 768px) {
                //     width: 432px;
                //     flex-direction: column-reverse;
                // }
                // @media (max-width: 513px) {
                //     width: 346px;
                // }
            
                // @media (max-width: 375px) {
                //     width: 300px;
                // }
                // @media (max-width: 320px) {
                //     width: 250px;
                // }
            
                background: rgba($color: white, $alpha: 10%);
                border-radius: 24px;
                display: flex;
                align-items: center;
                padding: 18px 24px;
                margin-top: 12px;
                span{
                    img{
                        @media (max-width: 460px) {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    @media (max-width: 768px) {
                        font-size: 13px;
                    }
                    
                }
            }
        }
    }
    .empty{
        width: 636px;
        height: 136px;
        padding: 24px;
        background: rgba($color: white, $alpha: 10%);
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 706px) {
            width: 80%;
        }
        h3{
            margin-bottom: 24px;
            color: white;
            font-size: 20px;
            font-weight: 700;
            margin-top: 0;
            text-align: center;
        }
        button{
            width: 100%;
            height: 56px;
            border-radius: 50px;
            font-size: 16px;
            font-weight: 700;
            background: #13ED29;
            color: #141212;
            @media (max-width: 380px) {
                font-size: 12px;
            }
            &:hover{
                background: #31FF47;
            }
        }
    }
}