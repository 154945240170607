.footer {
  width: 95vw;
  margin: 80px auto 0;
  max-width: 1440px;

  @media (max-width: 768px) {
    margin: 0 auto;
  }

  @media (max-width:500px) {
    margin-top: 50px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 24px;
    }

    .email {
      margin-left: 140px;
      display: flex;
      gap: 16px;

      @media (max-width: 768px) {
        margin-left: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      p {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        font-family: 'Onest Medium';
        cursor: pointer;
      }
    }

    .links {
      display: flex;
      gap: 24px;

      a {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-family: 'Onest Medium';

        line-height: 32px;
      }
    }
  }
}