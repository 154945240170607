.pay {
  margin: 120px 48px 0;

  @media (max-width: 768px) {
    margin: 120px 24px 0;
  }

  @media (max-width: 500px) {
    margin: 120px 10px 0;
  }

  .title {
    text-align: center;

    h2 {
      color: #FFF;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 32px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .text {
    margin-top: 32px;

    ul {
      margin-bottom: 25px;
      list-style: disc !important;
      margin-block-start: 0;
      margin-block-end: 0;
      padding: 0;

      li {
        margin: 0;
        margin-left: 30px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        list-style: disc !important;
      }

      p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px
      }
    }
  }
}