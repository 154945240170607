.size {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  z-index: 100;

  .size_window {
    position: fixed;
    width: 384px;
    min-height: 182px;
    border-radius: 24px;
    background: #2C2A2A;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    padding: 24px;

    .window_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }

      span {
        cursor: pointer;
        background-color: #141212;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .dropdown {
      border-radius: 12px;
      cursor: pointer;
      margin-top: 16px;
      overflow: hidden;
      // transition: .1s;

      @media (max-width: 768px) {
        display: none;
      }

      @media (max-width: 500px) {
        width: 343px;
      }

      @media (max-width: 375px) {
        width: 310px;
      }

      @media (max-width: 351px) {
        width: 86%;
      }

      @media (max-width: 320px) {
        width: 264px;
      }

      .up {
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
        background: rgba(255, 255, 255, 0.10);
        border-radius: 12px;
        height: 56px;
        padding: 0 16px 0 20px;

        li {
          list-style: none;
          font-family: 'Onest Medium';
          font-weight: 500;
          color: white;
          margin: 0;
          font-size: 20px;
        }

        span {
          font-size: 26px;
          display: flex;
          align-items: center;
          margin: 0;
          color: white;
          transition: .5s;
        }

        .active_arrow {
          transform: rotate(180deg);
        }

      }

      .container {
        overflow: hidden;

        .down_none {
          margin-top: 12px;
          position: absolute;
          background: #2C2A2A;
          padding: 0 16px 12px 12px;
          z-index: -2;
          opacity: 0;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 8px;
            color: #FFF;
            font-family: 'Onest Medium';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 120% */
            background: #2C2A2A;
            border-radius: 12px;

            &.active {
              background: rgba(255, 255, 255, 0.10);
              border-radius: 12px;
            }

          }
        }

        .down_active {
          position: none;
          background: #353535;
          border-radius: 12px;
          padding: 12px;
          position: absolute;
          width: 360px;
          margin-top: 5px;
          box-shadow: 0px 28px 40px 0px rgba(0, 0, 0, 0.48);
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 8px;
            color: #FFF;
            font-family: 'Onest Medium';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 120% */
            // background: #2C2A2A;
            border-radius: 12px;
            transition: .2s;

            &.active {
              background: rgba(255, 255, 255, 0.10);
              border-radius: 12px;
            }

            &:hover {
              background: rgba(255, 255, 255, 0.10);
            }
          }
        }
      }

    }

    .add_btn {
      margin-top: 16px;
      .add_to_cart {
        width: 100%;
        background: #13ED29;
        color: #141212;
        border-radius: 32px;
        height: 56px;
        padding: 4px 16px;
        font-size: 16px;
        font-style: normal;
        line-height: 32px;
        font-family: 'Onest Medium';
      }
    }

  }
}