.brand_container{
  width: 100%;
  display: flex;
  justify-content: center;
  .brand {
    width: 96%;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    @media (max-width: 500px) {
      width: 480px;
    }
    @media (max-width: 430px) {
      width: 377px;
    }
    @media (max-width: 375px) {
      width: 343px;
    }
    @media (max-width: 320px) {
      width: 300px;
    }
  
    .line {
      width: 100%;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.05);
      margin-bottom: 30px;
    }
  
    .container {
      position: relative;
      .brand_text {
        background: url('../../img/brand.png') no-repeat;
  
        @media (max-width: 1243px) {
          padding-top: 250px;
        }
  
        @media (max-width: 634px) {
          background-size: contain;
        }
  
        @media (max-width: 500px) {
          padding-top: 200px;
        }
  
        @media (max-width: 375px) {
          padding-top: 170px;
        }
  
       
        div{
          overflow: hidden;
          p {
            margin: 0;
            padding: 0;
            color: #FFF;
            text-align: right;
            font-size: 96px;
            font-style: normal;
            line-height: 128px;
            text-transform: uppercase;
            font-family: 'Onest Medium';

            @media (max-width: 1360px) {
              font-size: 70px;
            }
    
            @media (max-width: 634px) {
              font-size: 40px;
              line-height: 48px;
            }
    
            .mr {
              margin-right: 344px;
    
              @media (max-width: 634px) {
                margin-right: 0;
              }
            }
    
            .grey {
              font-family: 'Onest Medium';
              @media (max-width: 1243px) {
                color: rgba(255, 255, 255, 0.60);
              }
            }
          }
        }
  
        .text_left {
          @media (max-width: 634px) {
            text-align: left;
          }
          
        }
      }
    }
  
    .brand_shine {
      width: 260px;
      display: flex;
      align-items: center;
      gap: 12px;
  
      @media (max-width: 375px) {
        margin-top: 32px;
      }
  
      img {
        width: 48px;
        height: 48px;
      }
  
      .shine_text {
        p {
          span {
            color: rgba(255, 255, 255, 0.60);
          }
          font-family: 'Onest Regular';
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
        }
      }
    }
  }
}