.mobile_window {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  z-index: 100;

  .size_window {
    position: fixed;
    width: 90%;
    border-radius: 16px;
    background: #141213;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    padding: 22px 20px;

    .window_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }

      span {
        cursor: pointer;
        background-color: #141212;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .size_slider {
      padding-top: 24px;
      padding-bottom: 16px;
    }

    .add_btn {
      margin-bottom: 50px;

      .add_to_cart {
        width: 100%;
        background: #13ED29;
        color: #141212;
        border-radius: 32px;
        height: 56px;
        padding: 4px 16px;
        font-size: 16px;
        font-style: normal;
        line-height: 32px;
        font-family: 'Onest Medium';
      }
    }
  }
}