.logo {
  width: 90%;
  position: relative;
  margin: 150px auto 0;

  @media (max-width: 1024px) {
    margin-top: 100px;
  }

  @media (min-width: 1950px) {
    margin-bottom: 200px;
  }

  @media (min-width: 2500px) {
    margin-bottom: 300px;
  }

  @media (max-width: 425px) {
   display: none;
  }

  img {
    width: 100%;
    position: absolute;

    @media (max-width: 1024px) {
      top: 90px;
    }

    @media (max-width: 768px) {
      top: 120px;
    }


  }
}